import React from "react"
import { default as Link } from "../components/link"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import Message from "../components/message"

import ErrorImage from '../images/error.svg'

const NotFoundPage = () => (
    <Layout>
        <SEO title="Nicht gefunden" />
        <Message title="Nicht gefunden" image={<img src={ErrorImage} alt="Seite nicht gefunden" />}>
            <p>Dieser Inhalt konnte leider nicht gefunden werden.</p>
            <Link to="/" className="button">Zur Startseite</Link>
        </Message>
    </Layout>
)

export default NotFoundPage
